<template>
  <b-tabs content-class="mt-3 w-100" style="width: 100%">
    <b-tab title="Edytuj wynajem">
      <b-alert variant="danger" dismissible v-model="notValid"
        >Wprowadziłeś niekompletne dane - przed kolejną próbą zweryfikuj
        poprawność danych.</b-alert
      >
      <div class="d-flex justify-content-between">
        <div class="d-flex">
          <h4>Edycja danych rezerwacji</h4>
          <b-button variant="success" class="ml-3" @click="sendForm"
            >Aktualizuj</b-button
          >
          <b-button variant="danger" class="ml-3" @click="removeRental"
            >Usuń wynajem</b-button
          >
        </div>
        <div class="header-back" @click="goBack">
          Powrót
          <b-icon icon="backspace" />
        </div>
      </div>
      <b-row class="mb-4 space" v-if="getSingleRental">
        <b-col cols="6">
          <b-form-group label="Imię">
            <b-form-input v-model="getSingleRental.first_name" />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Nazwisko">
            <b-form-input v-model="getSingleRental.last_name" />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Wybierz samochód">
            <b-form-input v-model="getSingleRental.rented_car" disabled />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Opłata tygodniowa">
            <b-form-input
              type="number"
              v-model="getSingleRental.weekly_price"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Przebieg start">
            <b-form-input
              type="number"
              v-model="getSingleRental.mileage_start"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Przebieg koniec">
            <b-form-input type="number" v-model="getSingleRental.mileage_end" />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Start wynajmu">
            <b-form-datepicker
              start-weekday="1"
              v-model="getSingleRental.start_date"
              placeholder="Wybierz dzień"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Koniec wynajmu">
            <b-form-datepicker
              start-weekday="1"
              v-model="getSingleRental.end_date"
              placeholder="Wprowadź dzień zakończenia"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Godzina rozpoczęcia">
            <b-form-timepicker
              v-model="getSingleRental.start_time.slice(0, -4)"
              locale="pl"
              placeholder="Wybierz godzinę"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Godzina zakończenia">
            <b-form-timepicker
              v-model="getSingleRental.end_time"
              locale="pl"
              placeholder="Wybierz godzinę zakończenia"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-button
        variant="primary"
        class="float-right"
        v-if="
          getSingleRental.end_date &&
          getSingleRental.end_time &&
          getSingleRental.mileage_end &&
          getSingleRental.mileage_end > getSingleRental.mileage_start
        "
        >Zakończ wynajem</b-button
      >
    </b-tab>
  </b-tabs>
</template>

<script>
export default {
  data() {
    return {
      selectedDriver: null,
      notValid: false,
      isLoading: false,
      edit: null,
      sortBy: "serviceDate",
      editServiceId: null,
    };
  },

  methods: {
    sendForm() {
      if (this.getSingleRental) {
        this.$store
          .dispatch("updateRental", this.getSingleRental)
          .then((res) => {
            this.$bvModal.msgBoxOk("Zmiany zapisane pomyślnie.", {
              headerBgVariant: "primary",
              title: "Zapisano pomyślnie",
              headerTextVariant: "light",
              centered: true,
            });
          })
          .catch((err) => {
            this.$bvModal.msgBoxOk(
              "Pojawił się problem - może jest to tymczasowy błąd spróbuj ponownie później.",
              {
                headerBgVariant: "danger",
                title: "Błąd",
                headerTextVariant: "light",
                centered: true,
              }
            );
          });
      } else {
        // loop and look for errors
        this.notValid = true;
      }
    },
    removeRental() {
      const rental = {id: this.getSingleRental.id}
      this.$bvModal.msgBoxConfirm(
        "Wynajem usunięty zostanie na stałe, jeśli chcesz zachować go to wprowadź datę zakończenia, przebieg oraz czas zakończenia.",
        {
          headerBgVariant: "danger",
          title: "Potwierdź usunięcie wynajmu.",
          cancelTitle: "Anuluj",
          okTitle: "Usuń",
          headerTextVariant: "light",
          centered: true,
        }
      ).then(value => {
        if(value){
        this.$store
          .dispatch("removeRental", rental)
        }
        this.goBack();
      });
    },

    goBack() {
      this.$router.go(-1);
    },
  },

  computed: {
    getSingleRental() {
      const allRentals = this.$store.getters["getRentals"];
      const rentalId = this.$route.params.id;
      const findOne = allRentals.find((element) => element.id == rentalId);
      return findOne;
    },
  },
};
</script>

<style scoped>
.grounded-row {
  gap: 20px 0;
}
.header-back {
  cursor: pointer;
}
.grounded-box {
  background: #fff;
  padding: 15px;
  width: 80%;
  border-radius: 15px;
}
.grounded-reg {
  font-weight: 600;
  font-size: 1.2em;
}
.driver {
  border: 1px solid gray;
  padding: 10px;
}
.remove {
  color: rgb(189, 15, 15);
}
.pointer {
  cursor: pointer;
}
.modal-rows {
  margin: 20px;
  gap: 20px;
}
.selected {
  background-color: yellow;
}
.space {
  row-gap: 15px;
}
.start {
  background: rgb(186, 255, 186);
  padding: 10px;
  border-radius: 10px;
}
.end {
  background: rgb(255, 164, 164);
  padding: 10px;
  border-radius: 10px;
}
</style>
