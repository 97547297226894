<template>
  <div>
    <div class="container">
      <pin-checker v-if="!isCorrectPin" />
      <b-row class="d-flex justify-content-center mt-5">
        <img height="250px" src="../assets/agmer-krakow.png" />
      </b-row>
      <b-row v-if="returnCal.failed" class="text-center mt-5">
        <h4>
          Niestety, nie ma jeszcze wygenerowanego kalendarza - otrzymasz
          stosowne powiadomienie, kiedy będzie gotowy.
        </h4>
      </b-row>
      <b-row v-if="isCorrectPin && !returnChoosen.failed" class="mt-5">
        <div class="text-center m-auto">
          <h4>Kalendarz pracy</h4>
          <h5>Tydzień: {{ returnWeek }}</h5>
          <div class="button-row">
            <b-button
              @click="setPrev"
              class="ml-2"
              variant="danger"
              v-if="mode !== 'prev'"
              >Ustaw poprzedni tydzień</b-button
            >
            <b-button
              v-if="mode !== 'prev'"
              v-b-modal.add-shift
              class="ml-2 addshift"
              >Dobierz zmianę</b-button
            >
            <b-button
              class="ml-2"
              @click="changeMode"
              variant="warning"
              v-if="!returnSetCalendar.failed || mode === 'prev'"
              >{{
                mode === "current"
                  ? "Ustaw kolejny tydzień"
                  : "Ustaw obecny tydzień"
              }}</b-button
            >
          </div>
        </div>
      </b-row>
    </div>
    <div v-if="isCorrectPin && !isMobile() && !returnChoosen.failed">
      <h4 class="ml-5">Twoje zmiany</h4>
      <div class="days-row mb-3" v-if="!returnChoosen.failed && returnChoosen">
        <div
          v-for="(item, index) in returnChoosen.days"
          class="single-day"
          :key="item.day"
        >
          <div class="title">
            <strong
              v-if="
                item.day !== 1 &&
                item.month !== 1 &&
                returnChoosen.year === 2022
              "
              >{{ item.day }}.{{ item.month }}.{{ returnChoosen.year }}</strong
            >
            <strong v-else>{{ item.day }}.{{ item.month }}.2024</strong>

            <br />
          </div>
          <div class="dayname">
            {{ weekDays[index] }}
          </div>
          <div v-if="item.shift === 'Wolne'" class="freeday">
            {{ item.shift }}
          </div>
          <div v-for="car in item.cars" :key="car.reg">
            <div
              class="single-car"
              :class="[car.service && item.shift === 'Dzień' ? 'service' : '']"
              v-if="!car.notOnStation"
            >
              <div class="name">
                <strong v-if="!car.grounded">{{
                  car.drivers.length ? car.drivers[0].fullName : ""
                }}</strong>
                <strong v-if="car.grounded"> Niedostępny </strong>
              </div>
              <span class="shift"
                >{{ item.shift }}

                <div
                  v-if="
                    item.shift === 'Dzień rezerwa' ||
                    item.shift === 'Noc rezerwa'
                  "
                  class="warning"
                >
                  Czekaj na potwierdzenie.
                </div>
              </span>
              <div class="regplate" v-if=" item.shift === 'Dzień' ||
                    item.shift === 'Noc'">
                {{ car.reg }}
                <div
                  :id="car.reg + index"
                  v-if="car.service && item.shift === 'Dzień'"
                >
                  <b-icon icon="bullseye" variant="danger" />
                  <b-tooltip
                    placement="bottom"
                    :target="car.reg + index"
                    triggers="hover"
                  >
                    <strong>Zaplanowany serwis:</strong><br />
                    <div
                      class="service-box"
                      v-for="item in car.service"
                      :key="item.desc"
                    >
                      {{ item.desc }}<br />
                      {{ item.serviceTime[0] }}:{{ item.serviceTime[1] }}
                    </div>
                  </b-tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isCorrectPin && isMobile() && !returnChoosen.failed">
      <div class="days-row mb-3" v-if="!returnChoosen.failed && returnChoosen">
        <div v-for="index in 7" class="mobile-row" :key="index + '' + 'n'">
          <div class="single-day">
            <div class="title">
              <strong
                >{{ returnChoosen.days[index - 1].day }}.{{
                  returnChoosen.days[index - 1].month
                }}.{{ returnChoosen.year }}
              </strong>
            </div>
            <div class="dayname">
              {{ weekDays[index - 1] }}
            </div>
            <div
              v-if="returnChoosen.days[index - 1].shift === 'Wolne'"
              class="freeday"
            >
              {{ returnChoosen.days[index - 1].shift }}
            </div>

            <div
              v-for="car in returnChoosen.days[index - 1].cars"
              :key="car.reg"
            >
              <div
                class="single-car"
                :class="[
                  car.service && returnChoosen.days[index - 1].shift === 'Dzień'
                    ? 'service'
                    : '',
                ]"
                v-if="car.drivers.length"
              >
                <div class="name">
                  <strong
                    >{{ car.drivers[0].fullName.split(" ")[0] }}<br />{{
                      car.drivers[0].fullName.split(" ")[1] ||
                      car.drivers[0].fullName.split(" ")[2]
                    }}</strong
                  >
                  <br />
                  {{ returnChoosen.days[index - 1].shift }}
                  <div
                    v-if="
                      returnChoosen.days[index - 1].shift === 'Dzień rezerwa' ||
                      returnChoosen.days[index - 1].shift === 'Noc rezerwa'
                    "
                    class="warning"
                  >
                    Czekaj na potwierdzenie.
                  </div>
                </div>
                <div class="regplate">
                  {{ car.reg }}
                  <div
                    :id="car.reg"
                    v-if="
                      car.service &&
                      returnChoosen.days[index - 1].shift === 'Dzień'
                    "
                  >
                    <b-icon
                      icon="bullseye"
                      variant="danger"
                      :id="car.drivers[0].id.toString()"
                    />
                    <b-tooltip
                      placement="bottom"
                      :target="car.drivers[0].id.toString()"
                      triggers="click"
                      class="service-mobile"
                    >
                      <strong>Zaplanowany serwis:</strong><br />
                      <div
                        class="service-box"
                        v-for="item in car.service"
                        :key="item.id"
                      >
                        {{ item.desc }}<br />
                        {{ item.serviceTime[0] }}:{{ item.serviceTime[1] }}
                      </div>
                    </b-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <add-shift
      v-if="returnChoosen"
      :week="returnChoosen.week"
      :year="returnChoosen.year"
      :freeDays="returnFreeDays"
      :returnCal="returnChoosen"
      :mode="mode"
    />
  </div>
</template>

<script>
import PinChecker from "../components/PinCheckerNew.vue";
import addShift from "../components/modals/NewShift.vue";
export default {
  components: { PinChecker, addShift },
  data() {
    return {
      mode: "current",
      isLoading: false,
      addMode: false,
      weekDays: [
        "Poniedziałek",
        "Wtorek",
        "Środa",
        "Czwartek",
        "Piątek",
        "Sobota",
        "Niedziela",
      ],
    };
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      const phone = localStorage.getItem("phone");
      await this.$store.dispatch("fetchPublicSingleCalendar", { phone });
      await this.$store.dispatch("fetchCurrentSingleWeek", {
        week: this.returnCurrentWeek.week,
        year: this.returnCurrentWeek.year,
        phone,
      });
      let prevWeek = this.returnCurrentWeek.week - 1;

      let year = this.returnCurrentWeek.year;
      if (this.returnCurrentWeek.week === 1) {
        prevWeek = 52;
        year = 2023;
      }
      await this.$store.dispatch(
        "fetchPublicServices",
        this.returnCurrentWeek.week
      );
      await this.$store.dispatch("fetchPreviusSingleWeek", {
        week: prevWeek,
        year: year,
        phone,
      });
      this.isLoading = false;
      this.setServices();
    },
    changeMode() {
      if (this.mode === "current") {
        this.mode = "next";
      } else {
        this.mode = "current";
      }
    },
    setPrev() {
      this.mode = "prev";
    },
    isMobile() {
      var check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    setServices() {
      const services = this.returnServices;
      for (let item in services) {
        let reg = services[item].reg;
        let desc = services[item].desc;
        let id = crypto.randomUUID();
        let serviceTime = services[item].serviceTime.split(":");
        let day = services[item].serviceDate.slice(-2);
        let lookForDay = this.returnCal.days.find(
          (el) => el.day === parseInt(day)
        );
        let lookForCar = lookForDay.cars.find((el) => el.reg === reg);
        if (lookForCar) {
          lookForCar.service = null;
          if (!lookForCar.service) {
            lookForCar.service = [{ desc, serviceTime, id }];
          } else {
            if (!lookForCar.service.find((el) => el.id === id)) {
              // check if on serviceList
              lookForCar.service.push({ desc, serviceTime, id });
            }
          }
        }
      }
    },
  },
  computed: {
    isCorrectPin() {
      return this.$store.state.validPin;
    },
    returnServices() {
      return !this.isLoading && this.$store.getters["weekServices"];
    },
    returnCal() {
      return (
        this.isCorrectPin &&
        !this.isLoading &&
        this.$store.state.currentCalendar
      );
    },
    returnSetCalendar() {
      return (
        this.isCorrectPin && !this.isLoading && this.$store.state.publicCalendar
      );
    },
    returnPrevCalendar() {
      return (
        this.isCorrectPin && !this.isLoading && this.$store.state.previusWeek
      );
    },
    returnChoosen() {
      if (this.mode === "current") {
        return this.returnCal;
      }
      if (this.mode === "next") {
        return this.returnSetCalendar;
      }
      if (this.mode === "prev") {
        return this.returnPrevCalendar;
      }
    },
    returnFreeDays() {
      if (this.mode === "current") {
        return this.returnCal.leftSpaces;
      }
      if (this.mode === "next") {
        return this.returnSetCalendar.leftSpaces;
      }
      if (this.mode === "prev") {
        return this.returnPrevCalendar.leftSpaces;
      }
    },
    returnCurrentWeek() {
      const week = this.$dayjs().week();
      const year = this.$dayjs().year();
      return { week, year };
    },
    returnWeek() {
      if (this.returnChoosen && !this.returnChoosen.failed) {
        const isNewYear = this.returnChoosen.week == 1 ? 2024 : 2024;
        const start = this.$dayjs().week(this.returnChoosen.week).year(isNewYear).weekday(0);
        const end = this.$dayjs().week(this.returnChoosen.week).year(isNewYear).weekday(6);
        return (
          !this.isLoading &&
          `${start.$D}.${start.$M + 1}.${start.$y} - ${end.$D}.${end.$M + 1}.${
            end.$y
          }`
        );
      }
    },
  },
  watch: {
    isCorrectPin(newValue, oldValue) {
      if (newValue) {
        this.fetchData();
      }
    },
  },
};
</script>

<style scoped>
.days-row {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 20px;
}
.days-row .single-day {
  border: 1px solid rgb(211, 211, 211);
  width: 200px;
  text-align: center;
}
.regplate {
  color: #03037b;
}
.freeday {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 130px;
  font-weight: 600;
  border: 1px solid black;
  margin: 10px 5px;
}
.single-car {
  border: 1px solid black;
  margin: 10px 5px;
  min-height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.pointer {
  cursor: pointer;
}
.service {
  background: rgb(156, 226, 156);
}
.service-box {
  border: 1px solid #fff;
  margin: 5px 3px;
}
.title {
  background-color: rgb(196, 188, 188);
}

.warning {
  background: #dc3545;
  padding: 16px;
  border-radius: 8px;
  color: #fff;
  margin: 4px 4px;
}

@media (max-width: 800px) {
  .days-row {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .days-row .single-day {
    width: 80%;
  }
  .mobile-row {
    width: 90%;
    display: flex;
    justify-content: center;
  }
  .single-car,
  .freeday {
    min-height: 130px;
  }
  .button-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px 0;
    margin: 20px 0;
  }
}
</style>
